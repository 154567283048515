import React,{useEffect, useRef,useState, Component} from 'react';
import Mirsft from '../../Images/img/microsoft.jpg'
import TestPic from '../../Images/img/magpic2.png'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useParams, useSearchParams, useLocation} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import '../BodyStyle/styles.scss'
import Data from '../../data/linkedin/linkdata.json'
import Ads from '../../data/linkedin/ads.json'
import CarouselAds from '../../data/linkedin/ads-carousel.json'
import Like from '../../Images/img/linked_like.svg'
import Dummy from '../../Images/img/dummy.png'


function LinkedFeed() {


const [searchParams] = useSearchParams();
const ad = searchParams.get('ad');
const hpa = searchParams.get('hpa');




    const carouselAd=useRef(null)
    const HpaAd=useRef(null)
    const adVisibilityTime=useRef(0)
    const adHpaVisibilityTime=useRef(0)
    const visibilityInterval=useRef(null)
    const visibilityIntervalHpa=useRef(null)

    const postText=useRef(null)

    let checkVar=0


  useEffect(()=>{

    window.addEventListener('scroll', handleCalcScroll)

    // Carousel
    const callback=(entries, observerAd)=>{
  

  
      entries.forEach(entry=>{
        const {target, isIntersecting}=entry
        if(isIntersecting){
         
          if(visibilityInterval.current){
            clearInterval(visibilityInterval.current)
          }
         
    
          visibilityInterval.current=setInterval(()=>{
            adVisibilityTime.current++
           // console.log(adVisibilityTime.current)

            //eslint-disable-next-line
            sendResultFieldData({setResultFields: true, 1:String("visibility: " + CarouselAds.items[ad][0].item.title + " " + adVisibilityTime.current)})

          },1000)
        }
    
        else{
     
          clearInterval(visibilityInterval.current)
          
          //eslint-disable-next-line
          sendResultFieldData({setResultFields: true, 1:String("visibility: " + CarouselAds.items[ad][0].item.title + " " + adVisibilityTime.current)})
          
          
    
          
           
          
          
        }
    
    
      })
    }
    
    const options={
      threshold:0.4
    }
    
    const observerAd=new IntersectionObserver(callback, options)
    
    observerAd.observe(carouselAd.current)


 // HPA







    /* 
    let videoAd=document.getElementById("VideoAd")


    const callback=(entries, observer)=>{
  
      entries.forEach(entry=>{
         
        if(entry.intersectionRatio){
          videoAd.play()
        }
        if(entry.intersectionRatio<0.5){
          videoAd.pause()
        }
        
        
      })
    }
    
  
  
    const options={
      threshold:0.5
    }
  
  
  const observer= new IntersectionObserver(callback,options)
  observer.observe(videoAd)
*/





  const swiperEl = document.querySelector('swiper-container');

  swiperEl.swiper.on('slideChange',()=>{
    //eslint-disable-next-line
    sendResultFieldData({setResultFields: true, 2:String("swipe " + CarouselAds.items[ad][0].item.title)})
    console.log("swipe")
    
  })
  
},[])


const handleCalcScroll=()=>{
  
  let fullSize=(document.documentElement.scrollHeight-window.scrollY)-document.documentElement.clientHeight+window.scrollY
  let percentResult=Math.round((window.scrollY*100)/fullSize)
  
  if(percentResult>=checkVar){
    checkVar=percentResult
    
    //eslint-disable-next-line
    sendResultFieldData({setResultFields: true, 4:String("Scroll " + percentResult)})
  }
 
    //console.log("percent<check " + " check: " + checkVar + " percent: " + percentResult)


}


const handleOpenTextField=(e)=>{

  const parentDiv = e.target.closest('.FeedBodyMainText');
    const paragraph = parentDiv.querySelector('p'); 
  paragraph.style.webkitLineClamp='unset'

  e.target.style.display="none"

}

return Data.posts.map((item,index)=>{
 
try{

if(item.images_sk_img.length>1){

}
}
catch{
  
}

if(index===3){

  return (
    <div className="LinkedFeed" key={index}>


<div className='LinkedFeedHead'>
        
        
        
        
        <div className='FeedMore'><img/><MoreHorizIcon style={{marginRight:10, fontSize:20, color:'gray'}}/><CloseIcon style={{marginRight:10, fontSize:20,color:'gray'}}/></div>
  
        
      </div>


      
      <div className='FeedBody'>
        <div className='FeedBodyHead'>
          <div className='IconandName'>
           <div className='Icon'>
          <img src={CarouselAds.items[ad][0].item.logo}/>
          </div>
          <div className='FeedHeadText'>
          <div className='FeedHeadTextHead'><p>{CarouselAds.items[ad][0].item.title}</p></div>
          <div className='FeedHeadTextText'>Anzeige</div>
          <div className='FeedHeadTextText'></div>
          </div>
          </div>
        
       
        </div>

        <div style={{ height:'10px'}}></div>


          
      </div>



      <div className='linked-carousel_container' ref={carouselAd}>
                    <swiper-container id="linked-carousel" pagination="true" navigation="true" class="mySwiper" style={{width:'100%', height:'100%', position:'relative', overflow:'hidden'}} slides-per-view="1">
                   
                
                  <Sliders/>
                
                    </swiper-container>

                   
                    </div>

                    <div className='FeedFollow_ad' ><div className='ad_klicks ad_clickBtn' data-click-tracking={CarouselAds.items[ad][0].item.dataklicks}>Mehr erfahren</div></div>
    
     </div>
    
   

  );

}




else{


  return (
    <div className="LinkedFeed" key={index}>
      
      <div className='LinkedFeedHead'>
        
        
        
        
        <div className='FeedMore'><img/><MoreHorizIcon style={{marginRight:10, fontSize:20, color:'gray'}}/><CloseIcon style={{marginRight:10, fontSize:20,color:'gray'}}/></div>
  
        
      </div>


      
      <div className='FeedBody'>
        <div className='FeedBodyHead'>
          <div className='IconandName'>
           <div className='Icon'>
          <img src={item.logo}/>
          </div>
          <div className='FeedHeadText'>
          <div className='FeedHeadTextHead'><p>{item.username}</p></div>
          <div className='FeedHeadTextText'>{item.followers_count} Follower</div>
          <div className='FeedHeadTextText'>{item.post_date}</div>
          </div>
          </div>
          <div className='FeedFollow'><span>+</span> Folgen</div>
       
        </div>

        <div className='FeedBodyMainText' style={{ whiteSpace: 'pre-line' }}><p ref={postText}>{item.description}</p>
        <span onClick={handleOpenTextField} className='FeedBodyMainTextMore'>...mehr</span>
        </div>
       

        <div  className={item.images_sk_img.length > 1 ? 'FeedBodyTextandPicGrid' :  'FeedBodyTextandPic'}>
            


         {item.images_sk_img.length > 1 ? (
            item.images_sk_img.map((items,index)=>(
              <div className={item.images_sk_img.length === 3 && index===0 ? 'FeedBodyMainPicGrid Three':'FeedBodyMainPicGrid'}><img src={items}/></div>
             
            ))
         ):(
          <div className='FeedBodyMainPic'><img src={item.images_sk_img}/></div>
         )}


        
          </div>
          
      </div>
      <div className='Linked-Like-Comment'>
        <div className='Linked-Like'><img src={Like}/><span>{item.likes_count}</span></div>
        <div className='Linked-Comment'>{item.comments_count} Kommentare</div>
      </div>
      <hr className='FeedLine LineFeedBody'/>
      <div className='FeedFooter'>
     
        <div className='FooterIconDiv'><svg className='FooterIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="thumbs-up-outline-medium" data-supported-dps="24x24" fill="currentColor">
  <path d="M19.46 11l-3.91-3.91a7 7 0 01-1.69-2.74l-.49-1.47A2.76 2.76 0 0010.76 1 2.75 2.75 0 008 3.74v1.12a9.19 9.19 0 00.46 2.85L8.89 9H4.12A2.12 2.12 0 002 11.12a2.16 2.16 0 00.92 1.76A2.11 2.11 0 002 14.62a2.14 2.14 0 001.28 2 2 2 0 00-.28 1 2.12 2.12 0 002 2.12v.14A2.12 2.12 0 007.12 22h7.49a8.08 8.08 0 003.58-.84l.31-.16H21V11zM19 19h-1l-.73.37a6.14 6.14 0 01-2.69.63H7.72a1 1 0 01-1-.72l-.25-.87-.85-.41A1 1 0 015 17l.17-1-.76-.74A1 1 0 014.27 14l.66-1.09-.73-1.1a.49.49 0 01.08-.7.48.48 0 01.34-.11h7.05l-1.31-3.92A7 7 0 0110 4.86V3.75a.77.77 0 01.75-.75.75.75 0 01.71.51L12 5a9 9 0 002.13 3.5l4.5 4.5H19z"></path>
</svg> <span>Gefällt mir</span></div>
        <div className='FooterIconDiv'><svg className='FooterIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" width="24" height="24" focusable="false">
      <path d="M7 9h10v1H7zm0 4h7v-1H7zm16-2a6.78 6.78 0 01-2.84 5.61L12 22v-4H8A7 7 0 018 4h8a7 7 0 017 7zm-2 0a5 5 0 00-5-5H8a5 5 0 000 10h6v2.28L19 15a4.79 4.79 0 002-4z"></path>
    </svg><span>Kommentar</span></div>
        <div className='FooterIconDiv'><svg className='FooterIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="repost-medium" data-supported-dps="24x24" fill="currentColor">
  <path d="M13.96 5H6c-.55 0-1 .45-1 1v10H3V6c0-1.66 1.34-3 3-3h7.96L12 0h2.37L17 4l-2.63 4H12l1.96-3zm5.54 3H19v10c0 .55-.45 1-1 1h-7.96L12 16H9.63L7 20l2.63 4H12l-1.96-3H18c1.66 0 3-1.34 3-3V8h-1.5z"></path>
</svg><span>Teilen</span></div>
        <div className='FooterIconDiv'><svg className='FooterIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  id="send-privately-medium" data-supported-dps="24x24" fill="currentColor">
  <path d="M21 3L0 10l7.66 4.26L16 8l-6.26 8.34L14 24l7-21z"></path>
</svg><span>Senden</span></div>



<div className='FooterIconDivMobil'><svg className='FooterIconMobil' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="thumbs-up-outline-medium" data-supported-dps="24x24" fill="currentColor">
  <path d="M19.46 11l-3.91-3.91a7 7 0 01-1.69-2.74l-.49-1.47A2.76 2.76 0 0010.76 1 2.75 2.75 0 008 3.74v1.12a9.19 9.19 0 00.46 2.85L8.89 9H4.12A2.12 2.12 0 002 11.12a2.16 2.16 0 00.92 1.76A2.11 2.11 0 002 14.62a2.14 2.14 0 001.28 2 2 2 0 00-.28 1 2.12 2.12 0 002 2.12v.14A2.12 2.12 0 007.12 22h7.49a8.08 8.08 0 003.58-.84l.31-.16H21V11zM19 19h-1l-.73.37a6.14 6.14 0 01-2.69.63H7.72a1 1 0 01-1-.72l-.25-.87-.85-.41A1 1 0 015 17l.17-1-.76-.74A1 1 0 014.27 14l.66-1.09-.73-1.1a.49.49 0 01.08-.7.48.48 0 01.34-.11h7.05l-1.31-3.92A7 7 0 0110 4.86V3.75a.77.77 0 01.75-.75.75.75 0 01.71.51L12 5a9 9 0 002.13 3.5l4.5 4.5H19z"></path>
</svg> <span>Gefällt mir</span></div>
        <div className='FooterIconDivMobil'><svg className='FooterIconMobil' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" width="24" height="24" focusable="false">
      <path d="M7 9h10v1H7zm0 4h7v-1H7zm16-2a6.78 6.78 0 01-2.84 5.61L12 22v-4H8A7 7 0 018 4h8a7 7 0 017 7zm-2 0a5 5 0 00-5-5H8a5 5 0 000 10h6v2.28L19 15a4.79 4.79 0 002-4z"></path>
    </svg><span>Kommentar</span></div>
        <div className='FooterIconDivMobil'><svg className='FooterIconMobil' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="repost-medium" data-supported-dps="24x24" fill="currentColor">
  <path d="M13.96 5H6c-.55 0-1 .45-1 1v10H3V6c0-1.66 1.34-3 3-3h7.96L12 0h2.37L17 4l-2.63 4H12l1.96-3zm5.54 3H19v10c0 .55-.45 1-1 1h-7.96L12 16H9.63L7 20l2.63 4H12l-1.96-3H18c1.66 0 3-1.34 3-3V8h-1.5z"></path>
</svg><span>Teilen</span></div>
        <div className='FooterIconDivMobil'><svg className='FooterIconMobil' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  id="send-privately-medium" data-supported-dps="24x24" fill="currentColor">
  <path d="M21 3L0 10l7.66 4.26L16 8l-6.26 8.34L14 24l7-21z"></path>
</svg><span>Senden</span></div>


      </div>
    
    
     </div>

  );
}
})

function Sliders(){
  //CarouselAds.items[feedAd2][0].item._attributes.img
  return CarouselAds.items[ad].map((item,index)=>{
 
       return(
            <swiper-slide key={index} >
            <div className='feed-carousel-slide'>
            <img className='feed-carousel-slide-item' src={item.item.img}/>
            
            </div>
            </swiper-slide>
       )
  
  })
}



}


  


export default LinkedFeed;
