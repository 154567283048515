import {React, useEffect, useState, Component, useRef} from "react";
import '../../styles.scss'
import PowerBtn from '../assets/power.svg'
import Data from '../../../../data/tv/data.json'
import { Link } from "react-router-dom";
import {useParams, useSearchParams  } from 'react-router-dom';
import Ads from '../../../../data/tv/ads.json'
import BlackScreen from '../TvBlackScreen'
import AdsBlock from '../../../../data/tv/ads_atv.json'

//Bumper

import VoxSide from "../bumper/vox/VoxSide";
import VoxBottom from "../bumper/vox/VoxBottom";

import RtlSide from "../bumper/rtl/RtlSide";
import RtlBottom from "../bumper/rtl/RtlBottom";

// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function ETvspot(){

  const {tv}=useParams()
  const [searchParams] = useSearchParams();
  const tvad = searchParams.get('tvad');
  const kanal = searchParams.get('k');

  const sidebump=useRef(0)
  const footbump=useRef(0)
  const videoContainer=useRef(0)
  const videoAdContainer=useRef(0)
  const videoAd=useRef(0)
  const videoContent=useRef(0)
  const videoAdCount=useRef(0)
  const blackScreen=useRef(0)
  const videoLoader=useRef(null)
  const advideoLoader=useRef(null)

  const adblock=useRef(false)


  

  const[adCount, setAdCount]=useState(0)

  const[Contentlaunch, setContentlaunch]=useState(false)

  useEffect(()=>{

  },[])


  
  async function handleRandomizeAd(){ 

    let ads=[]
    let adsTr=[]
    ads=AdsBlock.ads.map((item,index)=>item.ad.url)
    adsTr=AdsBlock.ads.map((item,index)=>item.ad.descr)

    let adTemp 
    let adTrTemp 
 
   
  
      for(let i=ads.length-1;i>0; i--){
        let j = Math.floor(Math.random()*(i+1));
        adTemp=ads[i]
        ads[i]=ads[j]
        ads[j]=adTemp

        adTrTemp=adsTr[i]
        adsTr[i]=adsTr[j]
        adsTr[j]=adTrTemp
      }

   
        //eslint-disable-next-line
        sendResultFieldData({setResultFields: true, 2:String("atv: " + adsTr)})


     return [ads, adsTr]

  }


  async function startAdBlock(){
 
    let adsPlayed=[]

    const [ads, adsTr] = await handleRandomizeAd()

    videoAdContainer.current.style.display="block"
    videoContent.current.style.display="none"
   
    await new Promise((resolve, reject)=>{

      const AdStart=()=>{
        videoAd.current.removeEventListener('ended', AdStart)
        resolve()
      }

      videoAd.current.src=Data.data[tv]._attributes.url_start //url start
     
      videoAd.current.addEventListener('ended', AdStart)

 
        videoAd.current.play() 
      
     
    })
      
 
    for(let i=0; i<ads.length; i++){
      try{
        videoAd.current.src=ads[i]

        await videoAd.current.play()
        
        await new Promise((resolve, reject)=>{

          const AdVideoEnd=()=>{
            videoAd.current.removeEventListener('ended',AdVideoEnd)
             adsPlayed.push(adsTr[i])
            //eslint-disable-next-line
            sendResultFieldData({setResultFields: true, 3:String("ads: " + adsPlayed)})
            resolve()
          }

          const onError = (error) => {
            videoAd.current.removeEventListener('ended', AdVideoEnd);
            reject(error);
        };

          videoAd.current.addEventListener('ended',AdVideoEnd)
        })


      }
      
      catch(error){
        console.log(error)
        return false
      }
    }

   await new Promise((resolve, reject)=>{

    const AdBlockEnd=()=>{
      videoAd.current.removeEventListener('ended', AdBlockEnd)
     videoAdContainer.current.style.display="none"
      videoContent.current.style.display="block"
      videoContent.current.play()
      resolve()
    }

    videoAd.current.src=Data.data[tv]._attributes.url_end
   
    videoAd.current.addEventListener('ended', AdBlockEnd)


      videoAd.current.play() 
    
   
  })
    return true

  }




  const launch=(e)=>{
    if(Contentlaunch==false){
    setTimeout(() => {
      blackScreen.current.style.visibility="hidden"
      e.target.play()
      e.target.addEventListener('timeupdate', tvTime)
    }, 1500);
    setTimeout(() => {
      blackScreen.current.style.display="none"
      setContentlaunch(!Contentlaunch)
    }, 2500);
  }
  hideLoader()
  }

  const tvTime=(e)=>{

    
    
    if(Math.floor(e.target.currentTime)>=5 && !adblock.current){
      e.target.removeEventListener('timeupdate', tvTime)
      adOn(startAd)
    }

    else if(Math.floor(e.target.currentTime)>=e.target.dataset.time && adblock.current){
      e.target.pause()
      e.target.removeEventListener('timeupdate', tvTime)
      startAdBlock()

    }

  }

  const adTime=(e)=>{
   
      setAdCount(Math.round(e.target.duration-e.target.currentTime))

  }

  const adEnd=()=>{
    videoAd.current.removeEventListener('ended', adEnd)
    adOff()
  
  }


  const adOn=(callback)=>{
    adblock.current=true
    hideLoader()
  
    sidebump.current.style.width="20%"  

    footbump.current.style.width="80%"  
    footbump.current.style.height="20%"  
    videoContainer.current.style.width="80%"  
    videoContainer.current.style.height="80%"  

    
    callback()

  }

  const startAd=()=>{
    
    setTimeout(() => {
      
    
   
    videoContent.current.muted=true
    hideLoader()
    videoAdContainer.current.style.display="block"

    videoAd.current.play()
    videoAd.current.addEventListener('ended', adEnd)
    videoAdCount.current.style.opacity=1
    setAdCount(Math.round(videoAd.current.duration-videoAd.current.currentTime))
  }, 1500);
  }


  const adOff=()=>{
   
    videoAdContainer.current.style.display="none"
    sidebump.current.style.width="0%"  
    footbump.current.style.width="100%"  
    footbump.current.style.height="0%"  
    videoContainer.current.style.width="100%"  
    videoContainer.current.style.height="100%"  
    videoContent.current.muted=false
    videoContent.current.addEventListener('timeupdate', tvTime)
   
  }

  const showLoader=()=>{
   
    videoLoader.current.style.display="flex"
  
  }
    
  const hideLoader=()=>{
   videoLoader.current.style.display="none" 
   
  }
  const showAdLoader=()=>{
   
    advideoLoader.current.style.display="flex"
  
  }
    
  const hideAdLoader=()=>{
   advideoLoader.current.style.display="none" 
   
  }

  


    return(
      <>
        <div className="tv_etv_index-container">

        <div className="tv_etv_spot_content-container">
          <div className="tv_etv_spot_side-bumper" ref={sidebump}>
           
          <div className="tv_etv_spot_side-bumper_ad-contdown" ref={videoAdCount}>{adCount}</div>
           <SideBumper/>
           
          
          </div>
           <div className="tv_etv_spot_video-container" ref={videoContainer}>
            <div className="tv_etv_spot-blackscreen_container" ref={blackScreen}>
            <BlackScreen />
            </div>
            <Box className="tv_video_loader" ref={videoLoader}>
           <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
           </Box>
           <video ref={videoContent} playsInline src={Data.data[tv]._attributes.url} data-time={Data.data[tv]._attributes.time} onWaiting={showLoader} onCanPlay={launch}></video>
           <div ref={videoAdContainer} className="tv_etv_spot_videoad-container" >
           <Box className="tv_video_loader" ref={advideoLoader}>
           <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
           </Box>
           <video ref={videoAd} playsInline src={Ads.ads[tvad].url} onTimeUpdate={adTime}  onWaiting={showAdLoader} onCanPlay={hideAdLoader}></video>
           </div>
           </div>
           <div className="tv_etv_spot_foot-bumper" ref={footbump}>
          
            <BottomBumper/>
         
           </div>

           {/*Oder hier AdheadText*/}
        </div>


        </div>
    
        </>
    )


    function SideBumper(){

      const kanalID=Data.data[tv]._attributes.kanalid


      if(kanalID==0){ //rtl
        return(
          <RtlSide/>
        )
      }
      else if(kanalID==1){ // vox
      return(<VoxSide/>)
      } 
    }


    function BottomBumper(){
      const kanalID=Data.data[tv]._attributes.kanalid
      if(kanalID==0){ //rtl
        return(
          <RtlBottom/>
        )
      }
      else if(kanalID==1){ // vox
        return(<VoxBottom/>)
      } 
    }
}

export default ETvspot;