import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Ads from '../../../../data/inpage/ntv/ads.json'

import './styles.scss'


function SternInterstitial({adContent}) {

const adVideo=useRef(null)
const adImg=useRef(null)
const closeBtn=useRef(null)

const interstitialKlickRef=useRef(null)
  

const interAd=useRef(null)

const adVisibilityTime=useRef(0)
const visibilityInterval=useRef(null)

  useEffect(()=>{

   document.body.style.overflow="hidden"

    document.getElementById("chef-ad-MCA").style.display="none"

   window.addEventListener('scroll',iosNoScroll)


   const callback=(entries, observerInter)=>{
    entries.forEach(entry=>{
      const {target, isIntersecting}=entry
      if(isIntersecting){
        if(visibilityInterval.current){
          clearInterval(visibilityInterval.current)
        }

        visibilityInterval.current=setInterval(()=>{
          if(adVideo.current.readyState>=2){

            if (adVideo.current.paused) {
              adVideo.current.play();
            }

          interstitialKlickRef.current.style.pointerEvents="auto"
          adVisibilityTime.current++
        
          //eslint-disable-next-line
        sendResultFieldData({setResultFields: true, 3:String("visibility Chef-interstitial " + Ads.inter[adContent].ad +" : "+ adVisibilityTime.current)})
      }
        },1000)
      }
  
      else{
        //console.log("STOP")
        clearInterval(visibilityInterval.current)
       
        //eslint-disable-next-line
        sendResultFieldData({setResultFields: true, 3:String("visibility Chef-interstitial " + Ads.inter[adContent].ad +" : "+ adVisibilityTime.current)})
      }
  
  
    })
  }
  
  const options={
    threshold:0
  }
  
  const observerInter=new IntersectionObserver(callback, options)
  
  observerInter.observe(interAd.current)
    
 
  
  
},[])



function iosNoScroll(){
 window.scrollTo(0, 0);  
}


function closeInter(){
  document.getElementById("ntv-ad-inter").style.display="none"
    document.body.style.overflow="auto"
    window.removeEventListener('scroll',iosNoScroll)
     document.getElementById("chef-ad-MCA").style.display="block"
}


function interOff(){
 closeBtn.current.style.display="flex"



}



  return (
   <div className='ntv-ad-interstitial' ref={interAd} >

    <div className='ntv-ad-interstitial-container'>

      <div className='ntv-ad-interstitial-closeBtn' ref={closeBtn} onClick={closeInter}>X</div>
      
    <div className='ad_klicks InpageInterstitial' ref={interstitialKlickRef} data-click-tracking={Ads.inter[adContent].ad}>

    {Ads.inter[adContent].type == 'mp4' ? <video src={Ads.inter[adContent].url} onEnded={closeInter} playsInline muted autoPlay ref={adVideo}  onCanPlay={interOff}/> : <img src={Ads.inter[adContent].url } ref={adImg} onLoad={interOff}/>}
    
    </div>

    </div>
  
   </div>
 
  );


  }




export default SternInterstitial;
