import {React, useEffect, useState, Component, useRef} from "react";
import '../../styles.scss'
import PowerBtn from '../assets/power.svg'
import Data from '../../../../data/tv/data.json'
import { Link } from "react-router-dom";
import {useParams, useSearchParams, useLocation} from 'react-router-dom';



function TvIndex(){

  const params=useLocation()
 const startContainer=useRef(null)
 const tv1=useRef(null)
 const videoLoader=useRef(null)

  useEffect(()=>{
   
  },[])


  const startTv=()=>{
    startContainer.current.style.display="none"
  

  }


  const showLoader=()=>{
   
   // videoLoader.current.style.display="flex"
  
  }
    
  const hideLoader=()=>{
  // videoLoader.current.style.display="none" 
   
  }



    return(
      <>
        <div className="tv_etv_index-container">
        
        <div className="tv_etv-launchBtn-container" ref={startContainer}>
        <div className="tv_etv-launchBtn-btn-text-container">

         <div className="tv_etv-launchBtn-text-container">Zum Starten Klicken</div>   
        <div className="ad_klicks tv_etv-launchBtn-btn-container" data-click-tracking={"tv-start-btn"} onClick={startTv}>
        <img src={PowerBtn}/>
        </div>
        </div>
        </div>


        <div className="tv_etv_content-container">
            <div className="tv_content">

            <div className="tv_head-text">Wählen Sie ein Programm, das Sie sehen möchten</div>

            <div className="tv-tv-main-contntent">

            
            <div className="tv-tv-click-contntent">
              <div className="ad_klicks tv-tv-click-contntent-video" data-click-tracking={Data.data[0]._attributes.klick}>
          
              <Link to={`0/spot${params.search}`}><video ref={tv1} playsInline src={Data.data[0]._attributes.url} onWaiting={showLoader} onCanPlay={hideLoader} poster={Data.data[0]._attributes.poster}></video></Link>
              </div>
            </div>

              <div className="tv-tv-click-contntent">
            <div className="ad_klicks tv-tv-click-contntent-video" data-click-tracking={Data.data[1]._attributes.klick}>
             <Link to={`1/spot${params.search}`}><video playsInline src={Data.data[1]._attributes.url} poster={Data.data[1]._attributes.poster}></video></Link>
              </div>
            </div>
            <div className="tv-tv-click-contntent">
            <div className="ad_klicks tv-tv-click-contntent-video" data-click-tracking={Data.data[2]._attributes.klick}>
            <Link to={`2/spot${params.search}`}><video  playsInline src={Data.data[2]._attributes.url} poster={Data.data[2]._attributes.poster}></video></Link>
              </div>
            </div>
            <div className="tv-tv-click-contntent">
            <div className="ad_klicks tv-tv-click-contntent-video" data-click-tracking={Data.data[3]._attributes.klick}>
            <Link to={`3/spot${params.search}`}> <video  playsInline src={Data.data[3]._attributes.url} poster={Data.data[3]._attributes.poster}></video></Link>
              </div>
            </div>
            <div className="tv-tv-click-contntent">
            <div className="ad_klicks tv-tv-click-contntent-video" data-click-tracking={Data.data[4]._attributes.klick}>
            <Link to={`4/spot${params.search}`}> <video  playsInline src={Data.data[4]._attributes.url} poster={Data.data[4]._attributes.poster}></video></Link>
              </div>
            </div>

              </div>
             {/**/} 
            </div>

           
            </div>
        </div>


     
    
        </>
    )
}

export default TvIndex;