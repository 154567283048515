import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Ads from '../../../../data/inpage/ntv/ads.json'

import './styles.scss'


function SternMca({adContent}) {


  const mcaAd=useRef(null)
  const adVisibilityTime=useRef(0)
  const visibilityInterval=useRef(null)
  let isTouchMove = false;
   const adVideo=useRef(null)

  useEffect(()=>{

   
    
   const callback=(entries, observerMca)=>{
    entries.forEach(entry=>{
      const {target, isIntersecting}=entry
      if(isIntersecting){
        if(visibilityInterval.current){
          clearInterval(visibilityInterval.current)
        }

        visibilityInterval.current=setInterval(()=>{

          if (adVideo.current.paused) {
            adVideo.current.play();
          }

          adVisibilityTime.current++
          //console.log(adVisibilityTime.current)
           //eslint-disable-next-line
        sendResultFieldData({setResultFields: true, 4:String("visibility Chef-mca " + Ads.mca[adContent].ad +" : "+ adVisibilityTime.current)})
        },1000)
      }
  
      else{
        //console.log("STOP")
        clearInterval(visibilityInterval.current)
         //eslint-disable-next-line
         sendResultFieldData({setResultFields: true, 4:String("visibility Chef-mca " + Ads.mca[adContent].ad +" : "+ adVisibilityTime.current)})
      }
  
  
    })
  }
  
  const options={
    threshold:0
  }
  
  const observerMca=new IntersectionObserver(callback, options)
  
  observerMca.observe(mcaAd.current)


  ScrollEnd(()=>{

    mcaAd.current.style.pointerEvents="auto"

  


  },1000)



  
    
  
},[])



function mcaOff(){

  mcaAd.current.style.pointerEvents="none"
 


}





function ScrollEnd(callback, timeout){
  let timer=null
  
  window.addEventListener('scroll', function(){
    isTouchMove = true;

    mcaOff()
    if(timer){
      clearTimeout(timer)
    }

    timer=setTimeout(() => {

      callback()
      timer=null
      
    }, timeout);

  })
}





  return (
   <div className='ad_klicks ntv-ad-mca' id="chef-ad-MCA"  ref={mcaAd} data-click-tracking={Ads.mca[adContent].ad}>

    <div className='ntv-ad-mca-container'>
      
    {Ads.mca[adContent].type == 'mp4' ? <video src={Ads.mca[adContent].url} loop playsInline muted autoPlay ref={adVideo}/> : <img src={Ads.mca[adContent].url}/>}
    
    </div>
  
   </div> 
 
  );


  }




export default SternMca;
