import {React, useEffect, useState, Component, useRef} from "react";
import '../../styles.scss'
import PowerBtn from '../assets/power.svg'
import Data from '../../../../data/tv/data.json'
import { Link } from "react-router-dom";
import {useParams, useSearchParams, useLocation} from 'react-router-dom';

// Loader

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function ETvIndex(){

  const params=useLocation()
 const startContainer=useRef(null)
 const tv1=useRef(null)
 const videoLoader=useRef(null)

  useEffect(()=>{
   
  },[])


  const startTv=()=>{
    startContainer.current.style.display="none"
    tv1.current.play()

  }


  const showLoader=()=>{
   
   videoLoader.current.style.display="flex"
  
  }
    
  const hideLoader=()=>{
   videoLoader.current.style.display="none" 
   
  }



    return(
      <>
        <div className="tv_etv_index-container">
        
        <div className="tv_etv-launchBtn-container" ref={startContainer}>
        <div className="tv_etv-launchBtn-btn-text-container">

         <div className="tv_etv-launchBtn-text-container">Zum Starten Klicken</div>   
        <div className="ad_klicks tv_etv-launchBtn-btn-container" data-click-tracking={"atv-start-btn"} onClick={startTv}>
        <img src={PowerBtn}/>
        </div>
        </div>
        </div>


        <div className="tv_etv_content-container">
            <div className="tv_etv_content_grid">
            <div className="tv-etv-main-contntent">

            

              <div className="tv-etv-main-contntent-video">
              <Box className="tv_video_loader" ref={videoLoader}>
           <CircularProgress sx={{color:"white",filter:"drop-shadow(0px 0px 2px black);"}}/>
           </Box>
              <video ref={tv1} playsInline src={Data.data[0]._attributes.url} onWaiting={showLoader} onCanPlay={hideLoader}></video>
              </div>
             {/**/} 
            </div>

            <div className="tv-etv-click-contntent-container">
              <div className="tv-etv-container-layout">
            <div className="tv-etv-click-contntent">
            <div className="ad_klicks tv-etv-click-contntent-video" data-click-tracking={Data.data[1]._attributes.klick}>
             <Link to={`1/spot${params.search}`}><video playsInline src={Data.data[1]._attributes.url} poster={Data.data[1]._attributes.poster}></video></Link>
              </div>
            </div>
            <div className="tv-etv-click-contntent">
            <div className="ad_klicks tv-etv-click-contntent-video" data-click-tracking={Data.data[2]._attributes.klick}>
            <Link to={`2/spot${params.search}`}><video  playsInline src={Data.data[2]._attributes.url} poster={Data.data[2]._attributes.poster}></video></Link>
              </div>
            </div>
            <div className="tv-etv-click-contntent">
            <div className="ad_klicks tv-etv-click-contntent-video" data-click-tracking={Data.data[3]._attributes.klick}>
            <Link to={`3/spot${params.search}`}> <video  playsInline src={Data.data[3]._attributes.url} poster={Data.data[3]._attributes.poster}></video></Link>
              </div>
            </div>
            <div className="tv-etv-click-contntent">
            <div className="ad_klicks tv-etv-click-contntent-video" data-click-tracking={Data.data[4]._attributes.klick}>
            <Link to={`4/spot${params.search}`}> <video  playsInline src={Data.data[4]._attributes.url} poster={Data.data[4]._attributes.poster}></video></Link>
              </div>
            </div>
            </div>
            </div>
            </div>
        </div>


        </div>
    
        </>
    )
}

export default ETvIndex;