import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams, useSearchParams} from "react-router-dom";
import Head from './Head/Head';
import SternBb from './Ads/SternBb'
import SternHpa from './Ads/SternHpa'
import SternMca from './Ads/SternMca';
import SternSideBar from './Ads/SternSideBar'
import SternInterstitial from './Ads/SternInterstitial';
import Artikel from './Artikel/Artikel';
import Footer from './Footer/Footer';
import './styles.scss'
import OperaDialog from './OperaDialog'


function Index() {

    const [searchParams] = useSearchParams();
    const sb = searchParams.get('sb');
    const hpa = searchParams.get('hpa');
    const bb = searchParams.get('bb');
    const mr = searchParams.get('mr');
    const mca = searchParams.get('mca');
    const inter = searchParams.get('inter');

    //const [isOpera, setIsOpera]=useState(false)
 

    const mcaAdRef=useRef(null)
    const interAd=useRef(null)
    const bbAd=useRef(null)
    const sideAd=useRef(null)
    const hpaAd=useRef(null)
    const mrAd=useRef(null)

    const operaDial=useRef(null)


    let checkVar=0

    /*
    if (isOperaMobile()) {
  alert(" Opera Mobile!");
} else {
 alert("No Opera Mobile.");
}
    */


    function isOperaMobile() {

      if(/\bOPR|Opera\b/i.test(navigator.userAgent) && 'ontouchstart' in window){

       operaDial.current.style.display="block"
        

      }
     
    }
    



  useEffect(()=>{

 

  isOperaMobile()
  


  window.addEventListener('scroll', handleCalcScroll)




},[])


if(mca!=null){
  ScrollEnd(()=>{

    mcaOn()


  },1000)
  
}



function mcaOff(){
  mcaAdRef.current.style.opacity=0
  setTimeout(() => {
    mcaAdRef.current.style.display="none"
  }, 500);
 

}

function mcaOn(){
  mcaAdRef.current.style.display="flex"
 
  setTimeout(() => {
    mcaAdRef.current.style.opacity=1
  }, 0);
 

}


function ScrollEnd(callback, timeout){
  let timer=null
  
  window.addEventListener('scroll', function(){
    mcaOff()
    if(timer){
      clearTimeout(timer)
    }

    timer=setTimeout(() => {

      callback()
      timer=null
      
    }, timeout);

  })
}


const handleCalcScroll=()=>{
  
  
  let fullSize=(document.documentElement.scrollHeight-window.scrollY)-document.documentElement.clientHeight+window.scrollY
  let percentResult=Math.round((window.scrollY*100)/fullSize)

 
  if(percentResult>=checkVar){
    checkVar=percentResult
    //console.log("check: " + checkVar + " percent: " + percentResult)
    //eslint-disable-next-line
    sendResultFieldData({setResultFields: true, 7:String("Scroll " + percentResult)})
  }
 
    //console.log("percent<check " + " check: " + checkVar + " percent: " + percentResult)


}




  return (
    
   <div className='stern-container'>

<div className='opera_dialog' id="operDiag" ref={operaDial}>
 <OperaDialog/> 
 </div>

 <div className='stern-container_main'>

 <div className='stern-main-head_container'>
    <Head/>

    </div>


    <div className='stern-container_body'>

    {hpa!=null ? <div className='stern-ad-sidebar-main_container'><SternHpa adContent={hpa}/></div>: <div className='stern-ad-sidebar-main_container'> </div>}


    <div className='stern-article_container'>

    <div className='stern-main-billboard_container'>
    {bb !=null ? <div className='stern-ad-billboard-main_container'><SternBb adContent={bb}/></div> : <div className='stern-ad-nobillboard-main_container'></div> }
    </div>


    {mr !=null ? <Artikel adContent={mr}/> : <Artikel/>}

    </div>

    {sb !=null ? <div className='stern-ad-sidebar-main_container'> <SternSideBar adContent={sb}/></div>: <div className='stern-ad-sidebar-main_container'> </div>}

   

    </div>


 
 </div>

 {mca !=null ? <div className='ntv-ad-mca-main_container' ref={mcaAdRef} ><SternMca adContent={mca}/></div> : <div></div> }
 {inter !=null ? <div className='ntv-ad-inter-main_container' id="ntv-ad-inter" ><SternInterstitial adContent={inter}/></div> : <div></div> }


    
   </div>
   
 
  );


  
   
  }




export default Index;
