import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Data from '../../../../data/inpage/gala/data.json'
import './styles.scss'


function Inhalte() {

  const stars=["hochzeiten", "star-kinder", "quiz für experten", "stars + ihre familien","trennungen", "looks von roten teppich"]

  const beauty = [
    "Arganöl für die Haare",
    "Wimpern auffüllen",
    "Schwitzen im Gesicht",
    "80er-Jahre-Mode",
    "Getönte Tagescreme",
    "Lockenstab-Test",
    "Salzspray für die Haare",
    "Microdermabrasion"
  ];
  const lifestyle = [
    "TV-Ticker",
    "Gala im TV",
    "Bubble Shooter",
    "Stars im Urlaub",
    "Schlager-News",
    "Bauch-Beine-Po-Übungen",
    "Aszendent berechnen"
  ];
   
  const royals = [
    "Princess + Prince of Wales",
    "Spanische Royals",
    "Victoria + Daniel",
    "Style der Monegassinnen",
    "Mary + Frederik",
    "Spitznamen der Royals",
    "Prinzessin Leonor",
    "Prinzessin Estelle"
  ];
   
  const service = [
    "Abo",
    "Unsere Newsletter",
    "Horoskop",
    "Themen-Übersicht",
    "GALA-Archiv",
    "Sitemap",
    "Rss"
  ];
   


  useEffect(()=>{

    
    
  
},[])






  return (
    <>
     
     <div className="gala-inhalte-container">

      <div className='gala-newsticker-header_container'><p>Inhalte im Überblick</p></div>

    <div className='gala-inhalte_items'>
      <p className='gala-inhalte_items-header'>stars</p>
      <ul className='gala-inhalte-list'>
      <Content data={stars}/>
      </ul>
      </div>
  
    <div className='gala-inhalte_items'>
    <p className='gala-inhalte_items-header'>beauty + fashion</p>
    <ul className='gala-inhalte-list'>
      <Content data={beauty}/>
      </ul>
      </div>

    <div className='gala-inhalte_items'>
    <p className='gala-inhalte_items-header'>lifestyle</p>
    <ul className='gala-inhalte-list'>
      <Content data={lifestyle}/>
      </ul>
      </div>

    <div className='gala-inhalte_items'>
    <p className='gala-inhalte_items-header'>royals</p>
    <ul className='gala-inhalte-list'>
      <Content data={royals}/>
      </ul>
      </div>

    <div className='gala-inhalte_items'>
    <p className='gala-inhalte_items-header'>service</p>
    <ul className='gala-inhalte-list'>
      <Content data={service}/>
      </ul>
      </div>
  
            
      </div>
   </>
 
  );



  function Content({data}){
    return(

      data.map((item, index)=>{
        return(
          <li className='gala-inhalte'>
        <p>{item}</p>
        </li>
        )
      })
    )
  }



  }






export default Inhalte;
