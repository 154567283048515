import React,{useEffect, useState, Component, useRef} from 'react';
import { useParams, useSearchParams} from "react-router-dom";
import Head from './Head/Head';
import SternBb from './Ads/SternBb'
import SternHpa from './Ads/SternHpa'
import SternMca from './Ads/SternMca';
import SternSideBar from './Ads/SternSideBar'
import SternInterstitial from './Ads/SternInterstitial';
import Artikel from './Artikel/Artikel';
import Footer from './Footer/Footer';
import './styles.scss'


function OperaDialog() {

    
const dialog = useRef(null)


useEffect(()=>{



},[])


function CloseDialog(){
  dialog.current.style.display="none"
  document.getElementById("operDiag").style.display="none"
}




  return (
    
   <div className='opera_container-dialog' ref={dialog}>

    <div className='opera_container-dialog_container'>
    <div className='opera_container-text'>Bitte auf "Weiter" klicken</div>

    <div onClick={CloseDialog} className='opera_container-btn'>Weiter</div>
    </div>

 </div>
 
  );


  
   
  }




export default OperaDialog;
