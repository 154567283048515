import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams, useSearchParams} from "react-router-dom";
import Footer from '../Footer/Footer';
import Data from '../../../../data/inpage/gala/data.json'
import Ads from '../../../../data/inpage/ntv/ads.json'
import GalaMr from '../Ads/GalaMr';
import ArtikelHighLight from './ArtikelHighLight';
import ArtikelHighLight2 from './ArtikelHighLight2';
import ArtikelHighLight3 from './ArtikelHighLight3';
import ArtikelTeaser from './ArtikelTeaser';
import ArtikelTeaserBlock from './ArtikelTeaserBlock';
import ArtikelTeaserBlockStars from './ArtikelTeaserBlockStars';
import ArtikelTeaserItems from './ArtikelTeaserItems';
import ArtikelTeaserFooter from './ArtikelTeaserFooter';
import NewsTicker from './NewsTicker';
import Inhalte from './Inhalte';
import Dummy from '../assets/img/mainDummy.jpg'
import { register } from "swiper/element/bundle";
import './styles.scss'
register();


function Artikel({teaser, adContent}) {


   
  
    const mrAd=useRef(null)


  useEffect(()=>{

    const swiperEl = document.querySelector('swiper-container');

    if (window.innerWidth <= 576 && /Android|iPhone/i.test(navigator.userAgent)) {
      swiperEl.setAttribute('slides-per-view', '2'); 
      swiperEl.setAttribute('slides-per-group', '2');
      console.log("Android, Ios")
    
    }
   
  
},[])

//  

/*

  { range: [0, 1], layoutType: "highlight" },
        { range: [1, 4], layoutType: "teaser" },
        { range: [4, 5], layoutType: "medrec" },
        { range: [5, 11], layoutType: "teaserblock" },
        { range: [11, 12], layoutType: "highlight" },
        { range: [12, 15], layoutType: "teaseritem" },
        { range: [15, 16], range2: [16, 20], layoutType: "teaserblock-teaser" },
        { range: [20, 21], range2: [21, 23], layoutType: "teaserblock-teaser" },
        { range: [23, 24], layoutType: "highlight" },
        { range: [24, 26], range2: [26, 30], layoutType: "teaserblock-teaser" },
        { range: [30, 33], range2: [33, 35], layoutType: "teaserblock-teaser" },
        { range: [35, 36], layoutType: "highlight" },
        { range: [36, 39], layoutType: "teaseritem" },
        { range: [39, 40], layoutType: "highlight" },
        { range: [40, 41], range2: [41, 45], layoutType: "teaserblock-teaser" },
        { range: [45, 51], layoutType: "teaseritem" },
        { range: [51, 55], layoutType: "teaser" },
        { range: [55, 57], range2: [57, 59], layoutType: "teaserblock-teaser" },
        { range: [59, 60], layoutType: "highlight" },
        { range: [60, 64], layoutType: "teaser" },
        { range: [64, 66], range2: [66, 70], layoutType: "teaserblock-teaser" },
        { range: [70, 74], layoutType: "teaser" },
        { range: [74, 80], layoutType: "teaseritem" },


*/


  return (
    <>
 
 <div className='stern-main_article-container'>
   
       <SetArtikel adContent={adContent}/>
       <ArtikelTeaserFooter/>
       <NewsTicker/>
       <Inhalte/>
       <Footer/>



   </div>
   {/*teaser ? <SetMain adContent={adContent}/> : <SetMain2/>*/}
   


       
   </>
 
  );


  }


  function SetArtikel({adContent}) {

    const layoutsConfig = [
        { range: [6, 7], layoutType: "medrec" },
        { range: [7, 15], headtext:"Royals", layoutType: "teaseritem" },
        { range: [16, 24], headtext:"none",  layoutType: "teaseritem" },
        { range: [24, 30], layoutType: "teaserblock" },
        { range: [30, 33], headtext:"bilder", layoutType: "teaser" },
        { range: [33, 53], headtext:"beauty & fashion", layoutType: "teaseritem" },
        { range: [57, 63], layoutType: "teaserblock" },
        { range: [69, 72], headtext:"bilder", layoutType: "teaser" },
        { range: [72, 80], headtext:"lifestyle", layoutType: "teaseritem" }, 
        { range: [81, 93], layoutType: "stars" }, 
 
        
       
        
      ];
    
      const articlesWithLayout = layoutsConfig.map((config) => {
        const items=Data.channel.item.slice(...config.range)
        const itemsHeadText = config.headtext
        
        return{items, ...(itemsHeadText && { itemsHeadText }),
        layoutType: config.layoutType,}
      });
   


    return (
      <>
        {articlesWithLayout.map((item, index) => {
            
          switch (item.layoutType) {
            case "highlight":
              return (
                <div className='gala-hightlight_article'>
                     {item.items.map((item, index) => {
                     
                     if(index==0){
                        return(
          
                <ArtikelHighLight
                  key={index}
                  img={item.enclosure["@url"]}
                  category={item.category ? item.category[0] : ""}
                  title={item.title}
                 
                />
          
            )
          }
          else if(index>0 && index<=2){
            
            return(
            <ArtikelHighLight2
            key={index}
            img={item.enclosure["@url"]}
            category={item.category ? item.category[0] : ""}
            title={item.title}
           
          />
        )
          }
          else{

          }
        })}
                </div>
              );
              
             
              case "teaser":
             
              return (
                <>
               <div className='gala_container-header-img_container'><div className='gala_container-header-img_container'><p className="gala_container-header">{item.itemsHeadText}</p><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#B2804B" d="M20.67 22.67q.42-2.265 1.68-4.05c.84-1.2 1.8-2.2 2.89-2.96H0v-1.99h25.24a12 12 0 0 1-2.89-2.93c-.84-1.2-1.4-2.56-1.68-4.07h2.57c.27 1 .59 1.95.97 2.83.4.87.89 1.62 1.46 2.27a6.8 6.8 0 0 0 2.08 1.54c.8.38 1.75.57 2.83.57h.09v1.57h-.09a6.5 6.5 0 0 0-2.83.58c-.8.38-1.5.9-2.08 1.53a9 9 0 0 0-1.46 2.3c-.38.88-.7 1.82-.97 2.8h-2.57z"></path></svg></div></div>
               
                <div className="gala-article-container" key={index}>

                    {item.items.map((item, index) => {
                    
                    return(
                  <ArtikelTeaser
                    img={item.enclosure["@url"]}
                    category={item.category ? item.category[0] : ""}
                    title={item.title}
                    text={item.description}
                   // author={item.creator ? item.creator : ""}
                  />
                )
                })}
                
                 </div>
                 </>
               
              );


              case "medrec":
             
              return  adContent!=null ?(
                
                <div className="gala-article-medrec" key={index}>

                  <GalaMr adContent={adContent}/>

                 </div>
               
              ):null
                
        
           
            
            case "teaserblock":
              return (
                <div className='gala-artikel_teaser-block_container' key={index}>
                  <div className='gala-container-header'>
                  <p className="gala-container-header-text">aktuelle videos</p>
                  <div className='gala-stars_container-header_svg-container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#B2804B" d="M20.67 22.67q.42-2.265 1.68-4.05c.84-1.2 1.8-2.2 2.89-2.96H0v-1.99h25.24a12 12 0 0 1-2.89-2.93c-.84-1.2-1.4-2.56-1.68-4.07h2.57c.27 1 .59 1.95.97 2.83.4.87.89 1.62 1.46 2.27a6.8 6.8 0 0 0 2.08 1.54c.8.38 1.75.57 2.83.57h.09v1.57h-.09a6.5 6.5 0 0 0-2.83.58c-.8.38-1.5.9-2.08 1.53a9 9 0 0 0-1.46 2.3c-.38.88-.7 1.82-.97 2.8h-2.57z"/></svg>
                </div>
                  </div>
                     <div className='gala-artikel_teaser-block-body'>
                    
                     {item.items.map((item, index) => {
                    
                    return(
                <ArtikelTeaserBlock
                  key={index}
                  img={item.enclosure["@url"]}
                  category={item.category ? item.category[0] : ""}
                  title={item.title}
                  text={item.description}
                  //author={item.creator ? item.creator : ""}
                />
            )
        })}
                </div>
                </div>
              );
   
            case "teaseritem":
              return (
                <div className='gala-artikel_teaser-items_container'>
               <div className={`gala_container-header_container ${item.itemsHeadText}`}><div className='gala_container-header-img_container'><p className="gala_container-header">{item.itemsHeadText}</p><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#B2804B" d="M20.67 22.67q.42-2.265 1.68-4.05c.84-1.2 1.8-2.2 2.89-2.96H0v-1.99h25.24a12 12 0 0 1-2.89-2.93c-.84-1.2-1.4-2.56-1.68-4.07h2.57c.27 1 .59 1.95.97 2.83.4.87.89 1.62 1.46 2.27a6.8 6.8 0 0 0 2.08 1.54c.8.38 1.75.57 2.83.57h.09v1.57h-.09a6.5 6.5 0 0 0-2.83.58c-.8.38-1.5.9-2.08 1.53a9 9 0 0 0-1.46 2.3c-.38.88-.7 1.82-.97 2.8h-2.57z"></path></svg></div></div>
                <div className='gala-artikel_teaser-items-body'>
                {item.items.map((item, index) => {
                    
                    return(
                <ArtikelTeaserItems
                  key={index}
                  img={item.enclosure["@url"]}
                  category={item.category ? item.category[0] : ""}
                  title={item.title}
                  text={item.description}
                 
                />
            )
        })}

</div>
</div>
           
              );
           

              case "stars":
              return (
                <div className='gala-stars_container'>
                  <div className='gala-stars_container-header-container'>
                <p id="gala-stars_container-header">starporträts</p>
                <div className='gala-stars_container-header_svg-container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#B2804B" d="M20.67 22.67q.42-2.265 1.68-4.05c.84-1.2 1.8-2.2 2.89-2.96H0v-1.99h25.24a12 12 0 0 1-2.89-2.93c-.84-1.2-1.4-2.56-1.68-4.07h2.57c.27 1 .59 1.95.97 2.83.4.87.89 1.62 1.46 2.27a6.8 6.8 0 0 0 2.08 1.54c.8.38 1.75.57 2.83.57h.09v1.57h-.09a6.5 6.5 0 0 0-2.83.58c-.8.38-1.5.9-2.08 1.53a9 9 0 0 0-1.46 2.3c-.38.88-.7 1.82-.97 2.8h-2.57z"/></svg>
                </div>
                </div>
                <div className='gala-stars-swiper_container'>
         <swiper-container id="gala-artikel-swiper"  slides-per-view="6" slides-per-group="6" navigation="true"  css-mode="true">

         {item.items.map((item, index) => {
                     
                     
                        return(
          
                <ArtikelTeaserBlockStars
                  key={index}
                  img={item.url}
                  title={item.name}
                 
    
                 
                />
          
            )
          
       
        })}

        </swiper-container>
        </div>
                </div>
              );



   
            case "teaserblock-teaser":
              return (
                
                <div key={index}>
                    <div className="stern-article-container" key={index}>

                        {item.items.map((item, index) => {
                           
                        return(
                      <ArtikelTeaser
                    img={item.enclosure["@url"]}
                    category={item.category ? item.category[0] : ""}
                    title={item.title}
                    text={item.description}
                    //author={item.creator ? item.creator : ""}
                  />
                        )
                    })}
                </div>

                <div className='stern-artikel_teaser-block_container' key={index}>
                     <div className='stern-artikel_teaser-block-body'>
                     {item.itemsTeaserBlock.map((item, index) => {
                        return(
                  <ArtikelTeaserBlock
                    img={item.enclosure["@url"]}
                    category={item.category ? item.category[0] : ""}
                    title={item.title}
                    text={item.description}
                    //author={item.creator ? item.creator : ""}
                  />
                )
            })}
        </div>
        </div>
                </div>
              );
   
            default:
              return null;
          }
        })}
      </>
    );
  }


/* 
  function SetArtikel(){
    return Data.channel.item.map((item,index)=>{
        //const [beforeColon, afterColon] = item.title.split(':');
    console.log(index)

        return(
            
       <ArtikelHighLight img={item.enclosure['@url']} category={item.category ? item.category[0] : ""} title={item.title} text={item.description} author={item.creator ? item.creator : ""}/>
        
        )
    })

   }
  
*/
   
   function transformLink(originalLink) {
    return originalLink
        .replace('/img/incoming/crop', '/img/') 
        .replace('-cImg_4_3-w250', '/17-6/1136') 
        .replace(/\/img\/(\d+)\/(\d+)/, '/img/$1-$2/');
}


/* 
  function SetMain({adContent}){
    const [searchParams] = useSearchParams();
    const mr = searchParams.get('mr');
    const paramName = Array.from(searchParams.keys())[0];
   
    return Data.rss.channel.item.map((item,index)=>{
        const [beforeColon, afterColon] = item.title.split(':');
        const transformedLink=transformLink(item.enclosure._url)
        if(index>4 && index<10){

            if(index==5 && adContent!=null && paramName=="mr"){
    
                return(

                   <NtvMr adContent={adContent} key={index}/>
                )
            }
            else{

            return(
                <div className='ntv-article-day-container-main' key={index}>
                <div className='ntv-article-day-img-container-main'>
                <img className='ntv-article-day-img' src={item.enclosure._url}/>
                <img className='ntv-article-day-img_mobile-main' src={item.enclosure._url}/>
             
                </div>
        
                <div className='ntv-article-day-text-container-main'>
                <div className='ntv-article-main-theme'> {item.category}</div>
                <div className='ntv-article-main-desk-head'>
                {beforeColon}
                </div>

                <div className='ntv-article-main-desk-text'>{afterColon}</div>

                <div className='ntv-article-main-desk-desc-main'>{item.description}</div>     
                </div>
        
            </div>
            )
        }
        }

       
        
        else{
        return(
            <div className='ntv-article-main_container' key={index+10}>

            <div className='ntv-article-main_img-container'>
            <img src={transformedLink}/>
            </div>

            <div className='ntv-article-main-theme'>
             {item.category}
            </div>

            <div className='ntv-article-main-desk-head'>
                {beforeColon}
            </div>

         <div className='ntv-article-main-desk-text'>{afterColon}</div>
                   
         <div className='ntv-article-main-desk-desc'>{item.description}</div>        
           
 

            </div>
        )
    }
    })
    

   }
    */

   function transformLinkTeaser(originalLinkTeaser) {
    return originalLinkTeaser
    .replace('/img/incoming/crop', '/img/') 
    .replace('-cImg_4_3-w250', '/17-6/320') 
    .replace(/\/img\/(\d+)\/(\d+)/, '/img/$1-$2/');
}


 /* 
function SetArtikel() {
const groupedItems = Data.channel.item.reduce((acc, item, index) => {
    if (index === 0) acc.big.push(item);
    else if (index > 0 && index <= 3) acc.small.push(item);
    else acc.default.push(item);
    return acc;
  }, { big: [], small: [], default: [] });
 
  return (
    <>
      {groupedItems.big.map((item, index) => (
        <ArtikelHighLight
          key={index}
          className="big-article"
          img={item.enclosure['@url']}
          category={item.category ? item.category[0] : ""}
          title={item.title}
          text={item.description}
          author={item.creator || ""}
        />
      ))}
      {groupedItems.small.map((item, index) => (
        <ArtikelHighLight
          key={index}
          className="small-article"
          img={item.enclosure['@url']}
          category={item.category ? item.category[0] : ""}
          title={item.title}
          text={item.description}
          author={item.creator || ""}
        />
      ))}
      {groupedItems.default.map((item, index) => (
        <ArtikelHighLight
          key={index}
          className="default-article"
          img={item.enclosure['@url']}
          category={item.category ? item.category[0] : ""}
          title={item.title}
          text={item.description}
          author={item.creator || ""}
        />
      ))}
    </>
  );
}
*/


export default Artikel;
