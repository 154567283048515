import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Ads from '../../../../data/inpage/ntv/ads.json'
import './styles.scss'
import { Visibility } from '@mui/icons-material';


function NtvBb({adContent}) {


  const bbAd=useRef(null)
  const adVisibilityTime=useRef(0)
  const visibilityInterval=useRef(null)


  useEffect(()=>{




const callback=(entries, observerBb)=>{
  

  
  entries.forEach(entry=>{
    const {target, isIntersecting}=entry
    if(isIntersecting){
     
      if(visibilityInterval.current){
        clearInterval(visibilityInterval.current)
      }
     

      visibilityInterval.current=setInterval(()=>{
        adVisibilityTime.current++
        //console.log(adVisibilityTime.current)
        //eslint-disable-next-line
        sendResultFieldData({setResultFields: true, 1:String("visibility NTV-Bb " + Ads.bb[adContent].ad +" : "+ adVisibilityTime.current)})
      },1000)
    }

    else{
      //console.log("STOP")
      clearInterval(visibilityInterval.current)
      
      //eslint-disable-next-line
      sendResultFieldData({setResultFields: true, 1:String("visibility NTV-Bb " + Ads.bb[adContent].ad +" : "+ adVisibilityTime.current)})
      
      

      
       
      
      
    }


  })
}

const options={
  threshold:0
}

const observerBb=new IntersectionObserver(callback, options)

observerBb.observe(bbAd.current)
    
  
},[])






  return (
   <div className='ad_klicks ntv-ad-billboard' ref={bbAd} data-click-tracking={Ads.bb[adContent].ad}>

    <div className='ntv-ad-billboard-container'>
      {Ads.bb[adContent].type == 'mp4' ? <video src={Ads.bb[adContent].url} loop playsInline muted autoPlay/> : <img src={Ads.bb[adContent].url}/>}
    
    </div>
  
   </div>
 
  );


  }




export default NtvBb;
